import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SectionTitle from "../../../components/shared/section-title";
import Heading from "../../../components/shared/heading";
import Text from "../../../components/shared/text";
import Link from "../../../components/shared/link";
import MapContainer from "../../../components/map";
import {
  SectionWrap,
  ContactInfoWrap,
  SingleInfo,
  MapWrapper,
} from "./work-for-us-info.stc";

const ContactInfo = ({ infoTextStyle, infoHeadingStyle, infoLinkStyle }) => {
  const siteQueryData = useStaticQuery(graphql`
    query workForUsSiteDataQuery {
      site {
        siteMetadata {
          contact {
            chile {
              company_address
              company_email
              phone
              phone2
            }
            peru {
              company_address
              company_email
              phone
              phone2
            }
          }
        }
      }
    }
  `);
  const { chile, peru } = siteQueryData.site.siteMetadata.contact;
  return (
    <>
      <SectionWrap></SectionWrap>
      <SectionWrap></SectionWrap>
    </>
  );
};

ContactInfo.propTypes = {
  infoTextStyle: PropTypes.object,
  infoHeadingStyle: PropTypes.object,
};

ContactInfo.defaultProps = {
  infoTextStyle: {
    fontSize: "12px",
    color: "#000",
    opacity: 0.8,
    fontweight: 400,
    texttransform: "uppercase",
    mb: "5px",
  },
  infoHeadingStyle: {
    as: "h6",
    fontSize: "12px",
    fontweight: 600,
    color: "#000",
    letterspacing: "0.5px",
    mb: 0,
    lineHeight: "22px",
  },
  infoLinkStyle: {
    color: "#000",
  },
};

export default ContactInfo;
