import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Heading from '../../../components/shared/heading';
import Text from '../../../components/shared/text';
import Button from '../../../components/shared/button';
import SendButton from '../../../components/shared/sendButton';
import Form, { Input, Textarea } from '../../../components/shared/form';
import Image from '../../../components/image';
import { SendCV } from '../../../utils/mailSender';
import { validateField } from '../../../utils/validations';
import {
  ContactSectionWrap,
  BannerArea,
  ContactFormWrap,
  ContactForm,
} from './work-for-us-form.stc';

const ContactFormSection = ({
  headingStyle,
  textStyle,
  inputStyle,
  textareaStyle,
}) => {
  const bannerQueryData = useStaticQuery(graphql`
    query workForUsBannerQuery {
      pagedataJson(id: { eq: "work-for-us_page_data" }) {
        title
        subtitle
        banner_img {
          childImageSharp {
            fluid(maxWidth: 700, maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `);
  let [area, setArea] = useState('');
  let [inputs, setInputs] = useState({
    name: {
      value: '',
      error: false,
      errorText: '',
      validations: [{ name: 'required', value: true }],
    },
    email: {
      value: '',
      error: false,
      errorText: '',
      validations: [
        { name: 'required', value: true },
        { name: 'email', value: true },
      ],
    },
    phone: {
      value: '',
      error: false,
      errorText: '',
      validations: [{ name: 'required', value: true }],
    },
    title: {
      value: '',
      error: false,
      errorText: '',
      validations: [{ name: 'required', value: true }],
    },
    message: {
      value: '',
      error: false,
      errorText: '',
      validations: [{ name: 'required', value: true }],
    },
  });

  const sendObject = {
    name: inputs.name.value,
    phone: inputs.phone.value,
    email: inputs.email.value,
    title: inputs.title.value,
    area: area,
    message: inputs.message.value,
  };

  let handleInputs = (e) => {
    e.persist();
    let validation = validateField(
      e.target.value,
      inputs[e.target.name].validations
    );
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: {
        ...inputs[e.target.name],
        value: e.target.value,
        error: validation.value,
        errorText: validation.text,
      },
    }));
  };
  let handleSelect = (e) => {
    setArea(e.target.value);
  };

  const sendEmail = async () => {
    let send = await SendCV(sendObject);
    console.log(send);
  };
  const { title, subtitle } = bannerQueryData.pagedataJson;
  const imageData =
    bannerQueryData.pagedataJson.banner_img.childImageSharp.fluid;
  return (
    <ContactSectionWrap>
      <div className='row align-items-center'>
        <div className='col-2'>
          <BannerArea id='contact-form-banner'>
            <Image fluid={imageData} alt='Contact' />
          </BannerArea>
        </div>
        <div className='col-2 offset-1'>
          <ContactFormWrap>
            <div className='rn-project-meta-inner'>
              {title && <Text {...textStyle}>{title}</Text>}
              {subtitle && <Heading {...headingStyle}>{subtitle}</Heading>}
            </div>
            <ContactForm>
              <Form>
                <Input
                  type='text'
                  name='name'
                  id='name'
                  placeholder='Nombres Completos'
                  halfwidth
                  onChange={handleInputs}
                  {...inputStyle}
                />
                <Input
                  type='text'
                  name='title'
                  id='title'
                  placeholder='Título'
                  halfwidth
                  onChange={handleInputs}
                  {...inputStyle}
                />
                <Input
                  type='text'
                  name='phone'
                  id='phone'
                  placeholder='Teléfono'
                  halfwidth
                  onChange={handleInputs}
                  {...inputStyle}
                />
                <Input
                  type='email'
                  name='email'
                  id='email'
                  onChange={handleInputs}
                  placeholder='Email'
                  halfwidth
                />
                <select name='area' id='area' halfwidth onChange={handleSelect}>
                  <option disabled selected>
                    Área a la que deseas aplicar
                  </option>
                  <option value='Ingeniería Vial' label='Ingeniería Vial' />
                  <option
                    value='Ingeniería Ambiental'
                    label='Ingeniería Ambiental'
                  />
                  <option
                    value='Ingeniería de Detalle'
                    label='Ingeniería de Detalle'
                  />
                  <option
                    value='Geomesura y Topografía'
                    label='Geomesura y Topografía'
                  />
                  <option
                    value='Área Administrativa'
                    label='Área Administrativa'
                  />
                </select>
                <label style={{ marginTop: '18px', display: 'block' }}>
                  Adjunta tu CV
                </label>
                <input
                  style={{ paddingTop: '8px' }}
                  type='file'
                  id='cv'
                  name='cv'
                  accept='application/pdf'
                />
                <Textarea
                  name='message'
                  placeholder='Comentarios Adicionales'
                  onChange={handleInputs}
                  {...textareaStyle}
                />
                <SendButton onClick={sendEmail}>Enviar</SendButton>
              </Form>
            </ContactForm>
          </ContactFormWrap>
        </div>
      </div>
    </ContactSectionWrap>
  );
};

ContactFormSection.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
  textareaStyle: PropTypes.object,
};

ContactFormSection.defaultProps = {
  headingStyle: {
    fontSize: '40px',
    lineHeight: '55px',
    color: 'primary',
    fontweight: 600,
    responsive: {
      medium: {
        fontSize: '24px',
        lineHeight: 'initial',
      },
    },
  },
  textStyle: {
    as: 'span',
    color: '#000000',
    fontweight: 500,
    fontSize: '16px',
    texttransform: 'uppercase',
    letterspacing: '1px',
    display: 'inline-block',
    mb: '17px',
    lineHeight: 1,
    responsive: {
      medium: {
        fontSize: '12px',
        mb: '10px',
      },
    },
  },
  inputStyle: {
    responsive: {
      xsmall: {
        mb: '20px',
      },
    },
  },
  textareaStyle: {
    mt: '40px',
    mb: '40px',
    responsive: {
      xsmall: {
        mt: '25px',
        mb: '25px',
      },
    },
  },
};

export default ContactFormSection;
