import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import ContactFormSection from "../containers/work-for-us/work-for-us-form";
import ContactInfoSection from "../containers/work-for-us/work-for-us-info";

export default () => (
  <Layout headerLayout={2}>
    <SEO title="Trabaja con Nototros" />
    <ContactFormSection />
    <ContactInfoSection />
  </Layout>
);
